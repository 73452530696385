<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Balance, Deposit and Loan</p>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class=""
        style="font-size: 13px"
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <div v-if="$store.state.day_block > 0">
            <v-layout row wrap justify-space-around>
              <v-flex xs12 md6 class="text-center">
                <v-btn
                  small
                  text
                  class="primary ma-3"
                  dark
                  @click="dipositPopup = true"
                >
                  <span class="text-capitalize">Deposit</span>
                </v-btn>
                <v-btn
                  small
                  text
                  class="green ma-3"
                  dark
                  @click="getPackages"
                  :loading="getPackageLoading"
                >
                  <span class="text-capitalize">Buy Package</span>
                </v-btn>
                <v-btn
                  :loading="getLoanLoading"
                  small
                  text
                  class="orange ma-3"
                  dark
                  @click="getLoans"
                >
                  <span class="text-capitalize">Ask Loan</span>
                </v-btn>

                <v-btn
                  :loading="takeMoneyLoading"
                  small
                  text
                  class="pink ma-3"
                  dark
                  @click="takeMoney"
                  v-if="
                    partnerBalance.tempo_package >=
                    2 * partnerBalance.permanent_package
                  "
                >
                  <span class="text-capitalize">Take money</span>
                </v-btn>
              </v-flex>

              <v-flex xs12>
                <br />
              </v-flex>
              <v-flex xs10 md4 class="text-center">
                <div class="ma-1 pa-1" style="border: 1px solid cyan">
                  <p>
                    Current Balance:
                    {{ partnerBalance.partner_balance }} Birr
                  </p>
                  <span>Current Package </span><br />
                  <span
                    >{{ partnerBalance.tempo_package }}/{{
                      partnerBalance.permanent_package
                    }}</span
                  >
                </div>
                <div class="ma-1 mt-5 pa-1" style="border: 1px solid cyan">
                  <span>Credit or Loan Balance</span><br />
                  <span
                    >{{ partnerCreditBalance - partnerCreditBalance * 0.03 }}/{{
                      partnerCreditBalance
                    }}</span
                  >
                </div>
              </v-flex>
            </v-layout>
            <v-dialog
              v-model="dipositPopup"
              persistent
              max-width="420"
              transition="dialog-top-transition"
            >
              <v-card class="text-center">
                <v-card-title> Deposit Money </v-card-title>
                <v-card-text>
                  <P
                    >Make a deposit to our account and complete the ensuing
                    fields.</P
                  >
                  <span
                    >*For the account number, please contact the Admin <br />
                    *To learn more, check the help section
                  </span>
                </v-card-text>
                <v-form @submit.prevent="" class="pl-4 pr-4">
                  <v-card-text>
                    <v-text-field
                      v-model="amount"
                      :rules="[(v) => !!v || 'Required']"
                      label="Enter Amount"
                      required
                      dense
                      outlined
                      prepend-icon="attach_money"
                    ></v-text-field>
                    <v-text-field
                      v-model="referenceNumber"
                      :rules="[(v) => !!v || 'Required']"
                      label="Enter Transaction id"
                      required
                      dense
                      outlined
                      prepend-icon="book"
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="depositLoading"
                      color="green darken-1"
                      class="text-capitalize"
                      text
                      type="submit"
                      @click="addDeposit"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="dipositPopup = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="loanPopUp"
              persistent
              max-width="420"
              v-if="loanList.length > 0"
            >
              <v-card class="pl-10">
                <v-card-title> Loan or Credit </v-card-title>
                <v-card-text>
                  <v-layout row wrap class="pt-5">
                    <v-flex xs12>
                      <p style="font-size: 18px">
                        Your chance:
                        {{
                          partnerBalance.credit_chance -
                          partnerBalance.getCreditBalance.length
                        }}/{{ partnerBalance.credit_chance }}
                      </p>
                    </v-flex>
                    <v-flex xs12>
                      <p>All loans or credits are paid with 3% interest</p>
                    </v-flex>
                    <v-flex
                      class="ma-1"
                      xs12
                      v-for="(loan, i) in loanList"
                      :key="loan.loan_tbl_id"
                    >
                      <span
                        >{{ i + 1 }}. &nbsp;&nbsp; {{ loan.loan_amount }}</span
                      >
                      <v-btn
                        @click="addLoan(loan.loan_amount)"
                        v-if="i == 0"
                        text
                        class="ml-6"
                        style="border: 1px solid cyan"
                        small
                      >
                        <span class="text-capitalize blue--text">Buy</span>
                      </v-btn>
                      <v-btn
                        @click="addLoan(loan.loan_amount)"
                        v-else
                        text
                        class="ml-4"
                        style="border: 1px solid cyan"
                        small
                      >
                        <span class="text-capitalize blue--text">Buy</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>

                <v-form @submit.prevent="" class="pl-4 pr-4">
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="loanPopUp = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <v-dialog v-model="packagePopUp" persistent max-width="420">
              <v-card class="pl-10">
                <v-card-title> Buy Package </v-card-title>
                <v-card-text>
                  <v-layout
                    row
                    wrap
                    class="pt-3"
                    v-for="(packages, i) in packageList"
                    :key="packages.package_id"
                  >
                    <v-flex xs4 v-if="i == 0">
                      <h4 class="ml-6">Price</h4>
                    </v-flex>
                    <v-flex xs3 v-if="i == 0">
                      <h4 class="">Package</h4>
                    </v-flex>
                    <v-flex xs3 v-if="i == 0"> </v-flex>
                    <v-flex xs4>
                      <span
                        >{{ i + 1 }}. &nbsp;&nbsp; {{ packages.price }}</span
                      >
                    </v-flex>

                    <v-flex xs3>
                      <span> &nbsp;&nbsp; {{ packages.package_amount }}</span>
                    </v-flex>

                    <v-flex xs3>
                      &nbsp;&nbsp;
                      <v-btn
                        @click="
                          buyPackage(packages.package_amount, packages.price)
                        "
                        text
                        class=""
                        style="border: 1px solid cyan"
                        small
                      >
                        <span class="text-capitalize blue--text">Buy</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>

                <v-form @submit.prevent="" class="pl-4 pr-4">
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize"
                      dark
                      color="red"
                      text
                      @click="packagePopUp = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </div>
          <div v-else class="mt-16">
            <p v-if="!pageLoading" class="headline red--text text-center">
              Reachs maximum day, Please contact the admin
            </p>
          </div>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      partnerBalance: "",
      partnerDeposit: [],
      loanList: [],
      packageList: [],
      partnerCreditBalance: 0,
      dipositPopup: false,
      loanPopUp: false,
      packagePopUp: false,
      referenceNumber: "",
      amount: "",
      depositLoading: false,
      getLoanLoading: false,
      getPackageLoading: false,
      takeMoneyLoading: false,
    };
  },

  methods: {
    async getBalance() {
      this.pageLoading = true;
      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getPartnerById(partner_id:${this.$store.state.partner.partner_id}){
                     tempo_package
                     permanent_package
                     partner_balance
                     credit_chance
                     getCreditBalance{
                       credit_balance
                       credit_id
                     }

                   }

              }
              `,
          },
        });
        this.partnerBalance = partnerBalanceResult.data.data.getPartnerById[0];
        this.partnerCreditBalance = 0;

        for (let i = 0; i < this.partnerBalance.getCreditBalance.length; i++) {
          this.partnerCreditBalance =
            this.partnerCreditBalance +
            this.partnerBalance.getCreditBalance[i].credit_balance;
        }
      } catch (err) {
        alert(err);
      }

      try {
        var depositResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getDeposit(partner_id:${this.$store.state.partner.partner_id}){
                     deposit_id
                     partner_id
                     amount
                     transaction_id
                     deposit_date
                     is_confirmed

                   }

              }
              `,
          },
        });
        this.partnerDeposit = depositResult.data.data.getDeposit;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },

    async getLoans() {
      this.getLoanLoading = true;
      try {
        var loanResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
              getLoans{
                loan_tbl_id
                loan_amount
              }


              }
              `,
          },
        });
        this.loanList = loanResult.data.data.getLoans;

        this.loanPopUp = true;
      } catch (err) {
        alert(err);
      }
      this.getLoanLoading = false;
    },

    async getPackages() {
      this.getPackageLoading = true;
      try {
        var packageResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getPackages{
                 package_id
                 price
                 package_amount
               }


              }
              `,
          },
        });
        this.packageList = packageResult.data.data.getPackages;

        this.packagePopUp = true;
      } catch (err) {
        alert(err);
      }

      this.getPackageLoading = false;
    },
    async addDeposit() {
      var isTransactionIdUsed = 0;
      for (let i = 0; i < this.partnerDeposit.length; i++) {
        if (this.partnerDeposit[i].transaction_id == this.referenceNumber) {
          isTransactionIdUsed++;
        }
      }

      if (isTransactionIdUsed == 0) {
        if (this.referenceNumber != "" && this.amount != "") {
          if (this.partnerCreditBalance <= this.amount) {
            this.depositLoading = true;
            try {
              var adDdepositResult = await axios({
                method: "POST",
                url: this.$store.state.partnerURL,

                data: {
                  query: `mutation{
                        addDeposit(partner_id:${this.$store.state.partner.partner_id},
                        partner_full_name:"${this.$store.state.partner.partner_full_name}",amount:${this.amount},
                        loan_amount: ${this.partnerCreditBalance},
                        oldAmount:${this.partnerBalance.partner_balance}, transaction_id:"${this.referenceNumber}"){
                     statusMessage
                    }

              }
              `,
                },
              });

              var result =
                adDdepositResult.data.data.addDeposit[0].statusMessage;
              if (result.toString() == "true") {
                alert(
                  "Deposited successfully!, Please wait 1 to 10 minutes to process automatically"
                );

                this.dipositPopup = false;
                await this.getBalance();
              } else if (result.toString() == "false") {
                alert(
                  "Some thing went wrong please contact the owner of this system"
                );
              } else alert(result);
            } catch (err) {
              alert(err);
            }
            this.depositLoading = false;
          } else
            alert(
              "The amount you entered is not enough to cover your previous loan or credit.  please deposit more than your loan or credit"
            );
        } else alert("Please fill all the required fields");
      } else alert("This transaction id is already used");
    },

    async addLoan(amount) {
      const loanConfirm = confirm(
        "Are you sure you want to loan " + amount + " Birr"
      );
      if (loanConfirm) {
        if (
          this.partnerBalance.credit_chance >
          this.partnerBalance.getCreditBalance.length
        ) {
          try {
            var addloanResult = await axios({
              method: "POST",
              url: this.$store.state.partnerURL,

              data: {
                query: `mutation{
                        addLoan(partner_id:${this.$store.state.partner.partner_id},                       
                        credit_balance: ${amount},
                        oldAmount:${this.partnerBalance.partner_balance}){
                     statusMessage
                    }

              }
              `,
              },
            });
            var result = addloanResult.data.data.addLoan[0].statusMessage;
            if (result.toString() == "true") {
              alert("Credited successfully!");

              this.loanPopUp = false;
              await this.getBalance();
            } else if (result.toString() == "false") {
              alert(
                "Some thing went wrong please contact the owner of this system"
              );
            } else alert(result);
          } catch (err) {
            alert(err);
          }
        } else
          alert("You have finished your credit chance, please pay your loans");
      }
    },

    async takeMoney() {
      this.takeMoneyLoading = true;
      try {
        var takeMoneyResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                        takeMoney(
                          partner_id:${this.$store.state.partner.partner_id},
                          partner_name:"${this.$store.state.partner.partner_full_name}",  
                        moneyToTake:${this.partnerBalance.permanent_package}){
                     statusMessage
                    }

              }
              `,
          },
        });
        var result = takeMoneyResult.data.data.takeMoney[0].statusMessage;
        if (result.toString() == "true") {
          alert("Took  successfully!");
          await this.getBalance();
        } else if (result.toString() == "false") {
          alert(
            "Some thing went wrong please contact the owner of this system"
          );
        } else alert(result);
      } catch (err) {
        alert(err);
      }
      this.takeMoneyLoading = false;
    },

    async buyPackage(packages, amount) {
      const packageConfirm = confirm(
        "Are you sure you want to buy " +
          packages +
          " packages using " +
          amount +
          " Birr"
      );
      if (packageConfirm) {
        if (this.partnerBalance.partner_balance >= amount) {
          if (this.partnerBalance.tempo_package <= 1000) {
            try {
              var buyPackageResult = await axios({
                method: "POST",
                url: this.$store.state.partnerURL,

                data: {
                  query: `mutation{
                        buyPackage(partner_id:${
                          this.$store.state.partner.partner_id
                        },                       
                        new_package: ${packages},
                        partner_balance:${
                          this.partnerBalance.partner_balance - amount
                        }){
                     statusMessage
                    }

              }
              `,
                },
              });

              var result =
                buyPackageResult.data.data.buyPackage[0].statusMessage;
              if (result.toString() == "true") {
                await axios({
                  method: "POST",
                  url: this.$store.state.partnerURL,

                  data: {
                    query: `mutation{
                      addBoughtPackages(
                          package_with_price:"${
                            amount.toString() + "," + packages.toString()
                          }"                      
                        ){
                     statusMessage
                    }

              }
              `,
                  },
                });

                alert("Completed successfully!");
                this.packagePopUp = false;
                await this.getBalance();
              } else if (result.toString() == "false") {
                alert(
                  "Some thing went wrong please contact the owner of this system"
                );
              } else alert(result);
            } catch (err) {
              alert(err);
            }
          } else alert("You have to finish your packages first");
        } else
          alert(
            "Your current balance is insufficient to buy this package, please deposit first"
          );
      }
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getBalance();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
