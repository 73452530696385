<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pt-10">
    <v-form @submit.prevent="">
      <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-5 mt-16"
        id="login"
      >
        <v-flex xs12 class="">
          <p>Virtual Games</p>
          <p class="headline">Partner Login</p>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="Username"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="partnerLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="partnerLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    partnerLoginLoading: false,
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        this.partnerLoginLoading = true;

        try {
          var partnerLoginResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `{
               partnerLogin(user_name:"${this.username}",password:"${this.password}"){
                      partner_id
                      partner_full_name
                      partner_phone
                      partner_balance
                      user_name                      
                      tempo_package
                      permanent_package
                      credit_chance
                      is_active
                   
                      getCreditBalance{
                        credit_balance
                        credit_id
                      }
    
                   }
              }
              `,
            },
          });

          if (partnerLoginResult.data.data.partnerLogin.length > 0) {
            this.$store.dispatch(
              "loginPartner",
              partnerLoginResult.data.data.partnerLogin[0]
            );
            this.$router.push({ name: "home" });
          } else alert("Please enter correct username and password");
        } catch (err) {
          alert(err);
        }

        this.partnerLoginLoading = false;
      } else alert("Please fill all the required fields");
    },
  },
  created() {
    // if (this.$store.state.partner != "") this.$router.push({ name: "home" });
    // this.$store.dispatch("loginCashier", false);
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}
</style>
