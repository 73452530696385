<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Cashiers</p>
      <div v-if="$store.state.partner != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="$store.state.day_block > 0">
          <div
            v-if="!pageLoading"
            class="white text-center pa-10 pl-1 pr-1"
            id="report"
            style="border-radius: 3px"
          >
            <v-layout row wrap class="mb-15 pa-3" style="font-size: 14px">
              <horizontal-scroll
                class="horizontal-scroll white pa-3 pl-0"
                id="report"
              >
                <table style="width: 100%" class="ma-3">
                  <tr>
                    <th>User Name</th>
                    <th>Full Name</th>
                    <th>Phone Number</th>
                    <th>Address</th>

                    <th>Action</th>
                  </tr>
                  <tr v-for="(cashier, i) in cashiers" :key="i">
                    <td>
                      {{ cashier.user_name }}
                      <!-- <v-btn
                      small
                      text
                      @click="deleteCashier(cashier)"
                      class="mt-1"
                    >
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn> -->
                    </td>
                    <td>
                      {{ cashier.cashier_full_name }}
                    </td>
                    <td>
                      {{ cashier.cashier_phone }}
                    </td>
                    <td>
                      {{ cashier.cashier_address }}
                    </td>

                    <td>
                      <v-btn
                        v-if="is_allowed_to_change"
                        small
                        text
                        @click="editClicked(cashier)"
                        class="mt-5"
                      >
                        <v-icon class="blue--text">edit</v-icon>
                      </v-btn>
                      <v-btn
                        dark
                        class="pink pl-4 pr-4 mt-5"
                        x-small
                        text
                        v-if="cashier.is_active && is_allowed_to_change"
                        :loading="activateLoading"
                        @click="blockOrActivateCashier(cashier)"
                      >
                        <span class="text-capitalize">Block</span>
                      </v-btn>
                      <v-btn
                        dark
                        class="green mt-5"
                        x-small
                        text
                        v-if="!cashier.is_active && is_allowed_to_change"
                        :loading="activateLoading"
                        @click="blockOrActivateCashier(cashier)"
                      >
                        <span class="text-capitalize">unBlock</span>
                      </v-btn>

                      <v-btn
                        x-small
                        v-if="cashier.is_loggedin == 1"
                        text
                        @click="logout(cashier)"
                        class="mt-1"
                      >
                        <span class="red--text text-capitalize">Logout</span>
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </horizontal-scroll>
            </v-layout>
          </div>

          <v-dialog
            v-model="cashierRegOrUpdateDialog"
            persistent
            max-width="420"
          >
            <v-card class="text-center pl-5 pr-5 pb-10">
              <v-card-title style="font-size: 13px">
                {{ isRegistration }} <v-spacer></v-spacer>
                <v-btn text small @click="cashierRegOrUpdateDialog = false">
                  <span class="text-capitalize red--text">close</span>
                </v-btn></v-card-title
              >

              <v-form @submit.prevent="" ref="form">
                <v-card-text>
                  <P>Fill the following required fields</P>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="fullName"
                    :rules="[(v) => !!v || 'Required']"
                    label="Full Name"
                    required
                    dense
                    outlined
                    prepend-icon="person"
                  ></v-text-field>

                  <v-text-field
                    style="font-size: 11px"
                    v-model="phoneNumber"
                    :rules="[(v) => !!v || 'Required']"
                    label="Phone Number"
                    required
                    dense
                    outlined
                    prepend-icon="phone"
                  ></v-text-field>

                  <v-text-field
                    style="font-size: 11px"
                    v-model="address"
                    :rules="[(v) => !!v || 'Required']"
                    label="Cashier Address"
                    required
                    dense
                    outlined
                    prepend-icon="location_on"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="userName"
                    :rules="[(v) => !!v || 'Required']"
                    label="User Name"
                    required
                    dense
                    outlined
                    prepend-icon="group"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="password"
                    :rules="[(v) => !!v || 'Required']"
                    label="Password"
                    required
                    dense
                    outlined
                    type="password"
                    prepend-icon="lock"
                  ></v-text-field>

                  <v-btn
                    v-if="isRegistration == 'Cashier Registration'"
                    small
                    text
                    class="primary"
                    @click="registerCashier"
                    :loading="regOrUpdateLoading"
                    type="submit"
                  >
                    <span class="text-capitalize">Register</span>
                  </v-btn>

                  <v-btn
                    v-else
                    small
                    text
                    class="primary"
                    :loading="regOrUpdateLoading"
                    type="submit"
                    @click="updateCashier"
                  >
                    <span class="text-capitalize">Update</span>
                  </v-btn>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="editPartnerDialog" persistent max-width="420">
            <v-card class="text-center pl-5 pr-5 pb-10">
              <v-card-title style="font-size: 13px">
                Update partner <v-spacer></v-spacer>
                <v-btn text small @click="editPartnerDialog = false">
                  <span class="text-capitalize red--text">close</span>
                </v-btn></v-card-title
              >

              <v-form @submit.prevent="" ref="partnerForm">
                <v-card-text>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs9>
                      <v-text-field
                        style="font-size: 11px"
                        v-model="day_block"
                        :rules="[(v) => !!v || 'Required']"
                        label="Days to block"
                        required
                        dense
                        outlined
                        :hide-details="true"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2 v-if="!isNaN(day_block) && day_block != ''">
                      <v-btn
                        small
                        text
                        class="primary"
                        :loading="updateBlockingDateLoading"
                        @click="updateBlockingDate"
                      >
                        <span class="text-capitalize">Save</span>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-switch
                        @click="changeAllow"
                        :loading="allowCashierChangeLoading"
                        class="mr-5"
                        v-model="is_allowed_to_change"
                        label="Allow  partner to modify cashier"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12 class="text-left">
                      <v-switch
                        @click="changeMonitorAllow"
                        :loading="allowMonitorLoading"
                        class="mr-5"
                        v-model="allow_monitor_anywhere"
                        label="Allow monitor any where"
                      ></v-switch>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn
                        @click="clearMonitorCoordinate"
                        text
                        small
                        class="pink--text pl-3 pr-3 text-capitalize"
                      >
                        Clear monitor coordinates
                      </v-btn>
                    </v-flex>
                  </v-layout>

                  <br />
                  <br />
                  <P>Change partner information</P>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="partnerFullName"
                    :rules="[(v) => !!v || 'Required']"
                    label="Full Name"
                    required
                    dense
                    outlined
                    prepend-icon="person"
                  ></v-text-field>

                  <v-text-field
                    style="font-size: 11px"
                    v-model="partnerPhoneNumber"
                    :rules="[(v) => !!v || 'Required']"
                    label="Phone Number"
                    required
                    dense
                    outlined
                    prepend-icon="phone"
                  ></v-text-field>

                  <v-text-field
                    style="font-size: 11px"
                    v-model="shopNumber"
                    :rules="[(v) => !!v || 'Required']"
                    label="Shop Number"
                    required
                    dense
                    outlined
                    prepend-icon="money"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="partnerUserName"
                    :rules="[(v) => !!v || 'Required']"
                    label="partner User Name"
                    required
                    dense
                    outlined
                    prepend-icon="group"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="partnerPassword"
                    :rules="[(v) => !!v || 'Required']"
                    label="Partner Password"
                    required
                    dense
                    outlined
                    type="password"
                    prepend-icon="lock"
                  ></v-text-field>

                  <v-text-field
                    style="font-size: 11px"
                    v-model="supper_partner_username"
                    :rules="[(v) => !!v || 'Required']"
                    label="Supper Partner Username"
                    required
                    dense
                    outlined
                    prepend-icon="person"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="supper_partner_password"
                    :rules="[(v) => !!v || 'Required']"
                    label="Supper Partner Password"
                    required
                    dense
                    outlined
                    type="password"
                    prepend-icon="lock"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="monitor_username"
                    :rules="[(v) => !!v || 'Required']"
                    label="Monitor Username"
                    required
                    dense
                    outlined
                    prepend-icon="laptop"
                  ></v-text-field>
                  <v-text-field
                    style="font-size: 11px"
                    v-model="monitor_password"
                    :rules="[(v) => !!v || 'Required']"
                    label="Monitor Password"
                    required
                    dense
                    outlined
                    type="password"
                    prepend-icon="lock"
                  ></v-text-field>

                  <v-btn
                    small
                    text
                    class="primary"
                    type="submit"
                    :loading="updatePartnerLoading"
                    @click="updatePartner"
                  >
                    <span class="text-capitalize">Save changes</span>
                  </v-btn>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        <div v-else class="mt-16">
          <p v-if="!pageLoading" class="headline red--text text-center">
            Reachs maximum day, Please contact the admin
          </p>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      regOrUpdateLoading: false,
      activateLoading: false,
      partnerActivateLoading: false,
      cashiers: [],
      partner: [],
      cashierRegOrUpdateDialog: false,
      cashierId: "",
      isRegistration: "",
      fullName: "",
      phoneNumber: "",
      address: "",
      userName: "",
      password: "",
      editPartnerDialog: false,

      partnerFullName: "",
      partnerPhoneNumber: "",
      shopNumber: "",
      partnerUserName: "",
      partnerPassword: "",
      updatePartnerLoading: false,

      supper_partner_username: "",
      supper_partner_password: "",
      monitor_username: "",
      monitor_password: "",
      day_block_date: "",
      day_block: "",
      updateBlockingDateLoading: false,
      is_allowed_to_change: "",
      allow_monitor_anywhere: "",
      allowCashierChangeLoading: false,
      allowMonitorLoading: false,
    };
  },

  methods: {
    editClicked(cashier) {
      this.isRegistration = "Update Cashier";
      this.cashierRegOrUpdateDialog = true;
      this.fullName = cashier.cashier_full_name;
      this.phoneNumber = cashier.cashier_phone;
      this.address = cashier.cashier_address;
      this.userName = cashier.user_name;
      this.password = cashier.password;
      this.cashierId = cashier.cashier_id;
    },

    async changeAllow() {
      if (this.is_allowed_to_change) this.is_allowed_to_change = 1;
      else this.is_allowed_to_change = 0;
      this.allowCashierChangeLoading = true;
      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                       changeAllowModifyCashier(is_allowed_to_change:${this.is_allowed_to_change}){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllowModifyCashier[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.allowCashierChangeLoading = false;
    },

    async changeMonitorAllow() {
      if (this.allow_monitor_anywhere) this.allow_monitor_anywhere = 1;
      else this.allow_monitor_anywhere = 0;
      this.allowMonitorLoading = true;
      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                       changeAllowMonitor(allow_monitor_anywhere:${this.allow_monitor_anywhere}){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllowMonitor[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.allowMonitorLoading = false;
    },

    async changeAllowLoggin(cashier) {
      var isAllowed = "";
      if (cashier.is_allowed_anywhere) isAllowed = 1;
      else isAllowed = 0;

      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                       changeAllowLogin(
                        is_allowed_anywhere:${isAllowed},
                        cashier_id:${cashier.cashier_id}
                       ){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllowLogin[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }
    },

    async registerCashier() {
      if (this.$refs.form.validate()) {
        this.regOrUpdateLoading = true;
        try {
          var addCashierResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                addCashier(
                  partner_id:${this.partner[0].partner_id},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address
                :"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
                 cashier_id
              }
              
              }
              `,
            },
          });

          if (
            addCashierResult.data.data.addCashier[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinPartnerURL,

              data: {
                query: `mutation{
                addCashier(
                  partner_id:${this.partner[0].partner_id},
                  cashier_id:${addCashierResult.data.data.addCashier[0].cashier_id},

                    cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address
                :"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
              },
            });

            this.getCashiers();
            this.cashierRegOrUpdateDialog = false;
          } else alert("Cashier already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updateCashier() {
      if (this.$refs.form.validate()) {
        this.regOrUpdateLoading = true;
        try {
          var updateCashierResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                updateCashier(
                  cashier_id:${this.cashierId},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address:"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            updateCashierResult.data.data.updateCashier[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinPartnerURL,

              data: {
                query: `mutation{
                updateCashier(
                  cashier_id:${this.cashierId},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address:"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
              },
            });
            this.getCashiers();
            this.cashierRegOrUpdateDialog = false;
          } else alert("Cashier already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updatePartner() {
      if (this.$refs.partnerForm.validate()) {
        this.updatePartnerLoading = true;
        try {
          var updatePartnerResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                updatePartner(
                 partner_full_name:"${this.partnerFullName}",
                partner_phone:"${this.partnerPhoneNumber}",shop_number:"${this.shopNumber}",
                user_name:"${this.partnerUserName}",password:"${this.partnerPassword}",
                supper_partner_username: "${this.supper_partner_username}",
                supper_partner_password: "${this.supper_partner_password}",
                monitor_username: "${this.monitor_username}",
                monitor_password: "${this.monitor_password}",
                
                ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            updatePartnerResult.data.data.updatePartner[0].statusMessage.toString() ==
            "true"
          ) {
            this.getCashiers();
            this.editPartnerDialog = false;
          } else alert("No change, Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.updatePartnerLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updateBlockingDate() {
      this.updateBlockingDateLoading = true;
      try {
        var updateBlockingDateResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                changeBlockingDate(
                  day_block:${this.day_block}
                
                ){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          updateBlockingDateResult.data.data.changeBlockingDate[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
          this.editPartnerDialog = false;
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }
      this.updateBlockingDateLoading = false;
    },
    async blockOrActivateCashier(cashier) {
      this.activateLoading = true;
      try {
        var blockAcrivateCashierResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                blockOrActivateCashier(
                  cashier_id:${cashier.cashier_id}, is_active:${
              cashier.is_active == 1 ? 0 : 1
            }){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          blockAcrivateCashierResult.data.data.blockOrActivateCashier[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.activateLoading = false;
    },

    async blockOrActivatePartner() {
      this.partnerActivateLoading = true;
      try {
        var blockAcrivatePartnerResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
                blockOrActivatePartner(
                  partner_id:${this.partner[0].partner_id}, is_active:${
              this.partner[0].is_active == 1 ? 0 : 1
            }){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          blockAcrivatePartnerResult.data.data.blockOrActivatePartner[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.partnerActivateLoading = false;
    },

    async deleteCashier(cashier) {
      const deleteConfirm = confirm(
        "Are you sure you want to delete this cashier?"
      );
      if (deleteConfirm) {
        try {
          var deleteCashierResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `mutation{
                deleteCashier(
                  cashier_id:${cashier.cashier_id}){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            deleteCashierResult.data.data.deleteCashier[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinPartnerURL,

              data: {
                query: `mutation{
                deleteCashier(
                  cashier_id:${cashier.cashier_id}){
                 statusMessage
              }
              
              }
              `,
              },
            });
            this.getCashiers();
          } else alert("not deleted, Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },
    async getCashiers() {
      this.pageLoading = true;
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getCashiersByPartner(partner_id:${this.$store.state.partner.partner_id}  ){
                cashier_id
                partner_id 
                cashier_full_name
                cashier_phone
                cashier_address
                user_name
                password
                is_active
                is_loggedin
                is_allowed_anywhere
    
                }
              }
              `,
          },
        });
        this.cashiers = cashierResult.data.data.getCashiersByPartner;
      } catch (err) {
        alert(err);
      }

      try {
        var partnerResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getPartner {
                 partner_id
                 partner_full_name
                 partner_phone
                 shop_number
                 partner_balance
                 user_name
                 password
                 is_active

                 supper_partner_username
                 supper_partner_password
                 monitor_username
                 monitor_password
                 day_block_date
                 day_block
                 is_allowed_to_change
                 allow_monitor_anywhere
               }
              }
              `,
          },
        });
        this.partner = partnerResult.data.data.getPartner;
        this.partnerFullName = this.partner[0].partner_full_name;
        this.partnerPhoneNumber = this.partner[0].partner_phone;
        this.shopNumber = this.partner[0].shop_number;
        this.partnerUserName = this.partner[0].user_name;
        this.partnerPassword = this.partner[0].password;

        this.supper_partner_username = this.partner[0].supper_partner_username;
        this.supper_partner_password = this.partner[0].supper_partner_password;
        this.monitor_username = this.partner[0].monitor_username;
        this.monitor_password = this.partner[0].monitor_password;
        this.is_allowed_to_change = this.partner[0].is_allowed_to_change;
        this.allow_monitor_anywhere = this.partner[0].allow_monitor_anywhere;

        var sec = 24 * 60 * 60 * 1000;

        this.day_block =
          "Given days = " +
          this.partner[0].day_block +
          "  ;  Remain days = " +
          Math.round(
            this.partner[0].day_block -
              (Date.now() -
                Number(
                  this.partner[0].day_block_date != ""
                    ? this.partner[0].day_block_date
                    : 0
                )) /
                sec
          );

        this.day_block_date = this.partner[0].day_block_date;
      } catch (err) {}

      this.pageLoading = false;
    },

    async logout(cashier) {
      try {
        await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
               loggedInCashier(
                cashier_id:${cashier.cashier_id},
                is_loggedin:0
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        await this.getCashiers();
      } catch (err) {
        alert(err);
      }
    },
    async clearCoordinate(cashier) {
      try {
        await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
              clearCoordinate(
                cashier_id:${cashier.cashier_id}
              
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        await this.getCashiers();
      } catch (err) {
        alert(err);
      }
    },

    async clearMonitorCoordinate() {
      try {
        await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `mutation{
              clearMonitorCoordinate
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        await this.getCashiers();
      } catch (err) {
        alert(err);
      }
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getCashiers();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
